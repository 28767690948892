<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      @shown="onShown"
      @hidden="onHidden"
    >
      <b-card
        title="Settings"
      >
        <b-card-text class="mb-0">
          <!--        <span>Start/stop </span>-->
          <!--        <code>switch</code>-->
          <!--        <span> to </span>-->
          <!--        <code>true</code>-->
        </b-card-text>

        <!-- switch -->

        <div>
          <div class="demo-inline-spacing">
            <b-col class="col-md-5">
              <b-form-checkbox
                v-model="viewBotActivated"
                name="check-button"
                switch
                inline
              >
                Activate Viewbot for raided channel
              </b-form-checkbox>

            </b-col>
            <b-col>
              <b-form-checkbox
                v-model="viewListActivated"
                name="check-button"
                switch
                inline
              >
                Activate Viewlist for raided Channel
              </b-form-checkbox>

            </b-col>
          </div>
          <div class="demo-inline-spacing">
            <b-col class="col-md-5">
              <b-form-checkbox
                v-model="follow"
                name="check-button"
                switch
                inline
              >
                Follow raided Account
                <feather-icon
                  v-b-tooltip.hover.top="'Follows are deducted from your Package!  |  We suggest you use the Followpanel for Following, to send specific Messages'"
                  icon="AlertCircleIcon"
                  class="mr-25"
                  style="color: red"
                />
              </b-form-checkbox>

            </b-col>
            <b-col>
              <b-form-checkbox
                v-model="unfollowAgain"
                :disabled="!follow"
                name="check-button"
                switch
                inline
              >
                Unfollow raided Account after 1 day
                <feather-icon
                  v-b-tooltip.hover.top="'Only deducts 50% of Follows from your Account'"
                  icon="AlertCircleIcon"
                  class="mr-25"
                />
              </b-form-checkbox>

            </b-col>
          </div>
        </div>
        <b-button
          v-if="settingsChanged"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mb-1 mt-1"
          @click="saveSettings"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-25"
          />
          <span>Save Settings</span>
        </b-button>
      </b-card>
      <!--      <CreditHistory />-->
      <div>
        <b-card
          title="Bot settings"
        >
          <b-row>
            <b-col md="5">
              <b-form-group>
                Viewer-Count
                <feather-icon
                  v-b-tooltip.hover.top="'This Number is shown during the raid to everybody'"
                  icon="AlertCircleIcon"
                  class="mr-25"
                  style="margin-top: -2px"
                />
                <div class="mt-3">
                  <vue-slider
                    v-model="viewerCount"
                    :min="1"
                    :max="maxViewers"
                    :direction="'ltr'"
                    :drag-on-click="true"
                    :tooltip="'always'"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="5">
              <b-form-group>
                Viewlist-Count
                <feather-icon
                  v-b-tooltip.hover.top="'Number of Users in the View/Chat-List'"
                  icon="AlertCircleIcon"
                  class="mr-25"
                  style="margin-top: -2px"
                />
                <div class="mt-3">
                  <vue-slider
                    v-model="viewListCount"
                    :min="1"
                    :max="viewerCount"
                    :direction="'ltr'"
                    :drag-on-click="true"
                    :tooltip="'always'"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="5">
              <b-form-group>
                Raid-Duration
                <feather-icon
                  v-b-tooltip.hover.top="'Viewers drop to zero until set Time.'"
                  icon="AlertCircleIcon"
                  class="mr-25"
                  style="margin-top: -2px"
                />
                <div class="mt-3">
                  <vue-slider
                    v-model="raidDuration"
                    :min="1"
                    :max="1800"
                    :direction="'ltr'"
                    :drag-on-click="true"
                    :tooltip="'always'"
                  />
                </div>
              </b-form-group>
              {{ calcTime }}
            </b-col>
          </b-row>
        </b-card>
        <b-overlay
          :show="!follow"
          rounded="sm"
          @shown="onShown"
          @hidden="onHidden"
        >
          <b-card
            title="Followbot settings"
          >
            <b-row>
              <b-col md="5">
                <b-form-group>
                  Number of Followers
                  <feather-icon
                    v-b-tooltip.hover.top="'These Followers are sent during the first 2 Minutes after the Raid'"
                    icon="AlertCircleIcon"
                    class="mr-25"
                    style="margin-top: -2px"
                  />
                  <div class="mt-3">
                    <vue-slider
                      v-model="followCounter"
                      :min="0"
                      :max="getMaxFollowers"
                      :tooltip="'always'"
                      :direction="'ltr'"
                      :drag-on-click="true"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

          </b-card>
          <template #overlay>
            <div class="text-center">
              <!--              <b-icon-->
              <!--                icon="CaretRightFill"-->
              <!--                font-scale="3"-->
              <!--                animation="pulse"-->
              <!--              />-->
              <p id="startstop">
                Follow deactivated
              </p>
            </div>
          </template>
        </b-overlay>
      </div>

      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="cash"
            font-scale="3"
            animation="pulse"
          />
          <p id="cancel-label">
            Please buy a license
          </p>
        </div></template>
    </b-overlay>

  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BFormCheckbox, BIconCaretRightFill, BImg, BOverlay, BNavItemDropdown, BCardText, BCard, BFormInput, BButton, BFormGroup, BCol, BRow, BIcon, BIconCash, BFormValidFeedback, BDropdown, BDropdownItem, BDropdownDivider, BFormInvalidFeedback, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import VueSlider from 'vue-slider-component'
// import CreditHistory from '@/views/pages/Panel/CreditHistory.vue'

export default {
  components: {
    // CreditHistory,
    BCol,
    BOverlay,
    BRow,
    BImg,
    BNavItemDropdown,
    BFormCheckbox,
    BCard,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    VueSlider,
    BFormInput,
    BButton,
    VBTooltip,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BIconCaretRightFill,
    BIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      show: false,
      settingsChanged: false,
      viewerCount: 5,
      raidDuration: 60,
      follow: false,
      updatedSettings: 1,
      followCounter: 10,
      unfollowAgain: false,
      viewBotActivated: false,
      viewListActivated: false,
      maxViewers: 5,
      viewListCount: 5,
      remainingFollowers: 10,
    }
  },
  computed: {
    calcTime() {
      // eslint-disable-next-line no-mixed-operators
      const m = Math.floor(this.raidDuration % 3600 / 60).toString().padStart(2, '0')
      const s = Math.floor(this.raidDuration % 60).toString().padStart(2, '0')
      return `${m}:${s}`
    },
    followActive() {
      return this.follow
    },
    getMaxFollowers() {
      if (this.unfollowAgain) {
        return this.remainingFollowers * 2
      }
      return this.remainingFollowers
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    viewerCount() {
      if (this.viewerCount < this.viewListCount) {
        this.viewListCount = this.viewerCount
      }
    },
    follow() {
      if (!this.follow) this.unfollowAgain = false
    },
    timeslider() {
      const x = this.timeslider / 60
      const comma = (x * 10) % 10
      this.minutes = x - comma
      this.seconds = this.timeslider % 60
    },
  },
  updated() {
    if (this.updatedSettings >= 2) {
      this.settingsChanged = true
      this.updatedSettings = 0
    } else {
      this.updatedSettings += 1
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
  mounted() {
    const userData = getUserData()
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.show = true
        } else {
          this.maxViewers = snap.data().viewer
          this.viewerCount = snap.data().raid.viewers
          this.follow = snap.data().raid.followActive
          this.followCounter = snap.data().raid.followCounter
          this.unfollowAgain = snap.data().raid.unfollowAgain
          this.remainingFollowers = snap.data().follower.remaining
          this.viewBotActivated = snap.data().raid.viewBotActivated
          this.viewListActivated = snap.data().raid.viewListActivated
          this.raidDuration = snap.data().raid.raidDuration
          this.viewListCount = snap.data().raid.viewListCount

          this.viewListCount ??= 5
          this.viewerCount ??= 5
          this.follow ??= false
          this.followCounter ??= 5
          this.unfollowAgain ??= false
          this.remainingFollowers ??= 5
          this.viewBotActivated ??= true
          this.viewListActivated ??= true
          this.raidDuration ??= 5600
          // console.log(snap.data().Botpanel.chatinterval)
          // if (this.max > 15) { // TODO REMOVE
          //   this.max = 15
          // }
        }
      })
      .then(() => {
        this.settingsChanged = false
      })
  },

  methods: {
    saveSettings() {
      const userData = getUserData()
      const sx = this.$firebase.firestore()
      sx.collection('User')
        .doc(userData.id)
        .set({
          raid: {
            viewers: this.viewerCount,
            followActive: this.follow,
            followCounter: this.followCounter,
            unfollowAgain: this.unfollowAgain,
            viewBotActivated: this.viewBotActivated,
            viewListActivated: this.viewListActivated,
            raidDuration: this.raidDuration,
            viewListCount: this.viewListCount,
          },
        }, { merge: true }).then(() => {
          this.settingsChanged = false
        })
    },
    changepattern(country) {
      this.selectedCountry = country
    },
    switchlooptrue() {
      this.loop = true
    },
    switchloopfalse() {
      this.loop = false
    },
    // fetch(`https://${snap2.data().Link}.ngrok.io/start/${userData.id}/${this.chatbot}/${snap.data().twitchname}/${this.valueslider}/${this.timeslider[0]}/${this.timeslider[1]}`)
    onShown() {
      // Focus the cancel button when the overlay is showing
    },
    onHidden() {
      // Focus the show button when the overlay is removed
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
